.edit-report__field-set h3 {
  margin: 5px 5px 10px 5px;
}

.edit-report__field-set {
  border: solid 3px #fff;
  margin-bottom: 10px;
  padding: 5px;
}

.edit-report__field-set__items {
  display: flex;
  flex-wrap: wrap;
}

.edit-report__field-set__item {
  flex-grow: 1;
  max-width: 300px;
}

.edit-report__field-set-no-border {
  border: 0;
  padding: 0;
}

.edit-report__field-set-vertical .edit-report__field-set__items {
  display: block;
}

.edit-report__field-set-vertical .edit-report__field-set__item {
  max-width: 450px;
}

.report-edit__form-container {
  margin-right: 10px;
}

.view-report__panels {
  margin: 10px 10px 0 0;
}

.edit-report__panels__not-selected {
  margin: 0 auto;
  max-width: 300px;
}

.edit-report__panels__not-selected__icon {
  font-size: 120px;
  text-align: center;
}

.edit-report__panels__not-selected__text {
  text-align: center;
  font-size: 1.7em;
}

.edit-report__panels__tabs {
  min-width: 320px;
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
}

.edit-report__panels__tabs__tab {
  min-height: 60px;
  background-color: #333;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  border: solid 3px #fff;
  border-right: 0;
  border-bottom: 0;
}

.edit-report__panels__tabs__tab.edit-report__panels__tabs__last-tab {
  border-bottom: 3px solid #fff;
}

.edit-report__panels__tabs__tab__status {
  align-self: center;
  font-size: 32px;
  width: 42px;
  margin-left: 10px;
}

.edit-report__panels__tabs__tab__text {
  align-self: center;
  font-size: 1.3em;
  margin-left: 10px;
}

.edit-report__panels__tabs__tab__icon {
  align-self: center;
  font-size: 32px;
  margin-left: auto;
  margin: 0 10px 0 auto;
  width: 42px;
  text-align: center;
}

.edit-report__panels__error {
  background-color: #9E2420;
  margin: 10px 10px 5px 5px;
  padding: 10px;
  display: flex;
  font-size: 1.2em;
  align-items: center;
  max-width: 285px;
}

.edit-report__panels__error__icon {
  font-size: 32px;
  padding-right: 10px;
}

.tab__selected {
  background-color: var(--color-blue);
}

.tab__errored {
  background-color: #9E2420;
}

.edit-report__panels__content {
  border: solid 3px #fff;
  flex-grow: 1;
  padding: 10px;
  margin-left: 320px;
  min-height: 250px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.edit-report__panels__hidden {
  display: none;
}

.edit-report__panels__content h2,
.edit-report__panels__content h3 {
  margin-top: 0;
}


.edit-report__panels__content__buttons {
  text-align: right;
  margin-top: auto;
}

.edit-report__mobile-view .edit-report__field-set__items {
  display: block;
}

.edit-report__mobile-view .edit-report__field-set__item {
  max-width: inherit;
}

.value-list__error__container {
  border: 3px solid #9E2420;
  padding-top: 5px;
}

.value-list__error__message {
  background-color: #9E2420;
  text-align: right;
  padding: 5px;
  font-size: 1.3em;
}

.edit-report__value-list {
  margin-bottom: 10px;
}

.edit-report__value-list__item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.edit-report__value-list__item__label {
  font-weight: bold;
  font-size: 1.4em;
  padding: 5px 10px 5px 5px;
  min-width: 50px;
  text-align: right;
}

.edit-report__value-list__buttons {
  display: flex;
  padding-bottom: 10px;
}

.edit-report__value-list__buttons .edit-report__value-list__item__label {
  margin-right: 5px;
}

.edit-report__value-list .form__item__outer {
  margin-bottom: 0;
}

.edit-report__value-list .form__item {
  display: block;
}

.edit-report__value-list .form-control {
  max-width: 150px;
}

.edit-report__mobile-view .edit-report__panels__tabs {
  float: none;
  border-right: solid 3px #fff;
  margin-bottom: 20px;
}

.edit-report__mobile-view .edit-report__panels__tabs__tab.tab__selected {
  border: solid 3px #fff;
  margin-bottom: 10px;
}

.edit-report__mobile-view .edit-report__panels__content {
  margin-left: 0;
}

.tooltip-foundation-option {
  display: inline-block;
  padding: 4px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
}

.edit-report__matrix {
  display: inline-block;
  margin-right: 40px;
  vertical-align: top;
}

.edit-report__matrix__table-field {
  font-size: 1em;
  width: 60px;
}

.edit-report__matrix__table-cell {
  font-size: 1.4em;
}

.edit-report__matrix__error {
  display: flex;
  margin-top: 10px;
  padding: 10px;
  background-color: #9E2420;
  text-align: right;
  max-width: 285px;
}

.edit-report__matrix__error__icon {
  font-size: 32px;
  padding-right: 25px;
}

.edit-report__matrix__error__text {
  flex-grow: 1;
}

.edit-report__mobile-view .edit-report__matrix {
  margin-right: 0;
  margin-bottom: 20px;
}

.edit-report__submit-container {
  padding: 10px;
  border: solid 3px #fff;
  display: flex;
  margin-top: 20px;
}

.edit-report__submit-container__error {
  background-color: #9E2420;
  padding: 10px;
  margin-right: 25px;
  font-size: 1.4em;
}

.edit-report__submit-container .button {
  margin-left: auto;
  max-height: 52px;
}

.form__item-checkbox__label {
  display: flex;
}

.form__item-checkbox__checkbox__input {
  width: 28px;
  height: 28px;
  margin-right: 14px;
}

.edit-report__infinite-form {
  border: solid 3px #fff;
  padding: 5px;
}

.edit-report__infinite-form__buttons {
  display: flex;
  gap: 3px;
  justify-content: flex-end;
}

.edit-report__infinite-form__summary__items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.edit-report__infinite-form__summary__item {
  display: flex;
  padding: 0 0 10px 5px;
}

.edit-report__infinite-form__summary__item__row-number {
  font-size: 32px;
  margin-right: 20px;
  width: 45px;
  padding-left: 15px;
  border-left: solid 5px #fff;
}

.edit-report__infinite-form__summary__item__row-values {
  flex-grow: 1;
  font-size: 1.1em;
}

.edit-report__infinite-form__summary__item__row-values > div {
  display: inline-block;
  width: 45%;
  margin-right: 15px;
}

.edit-report__infinite-form__summary__item__row-value__container {
  display: flex;
}

.edit-report__infinite-form__summary__item__row-value__label {
  flex-grow: 1;
}

.edit-report__infinite-form__summary__item__row-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media all and (max-width: 1060px) {
  .edit-report__infinite-form__summary__item__row-values > div {
    width: 95%;
  }
}
